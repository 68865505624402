.BasicKit{
  margin:50px auto;
  border-radius:50px;
  box-shadow:0px 2px 15px 0 rgba(0,0,0,0.3);
  width:80%;
  position: relative;
}
.BasicKit__quiryBtn{
  position: absolute;
  bottom:10%;
  left:30%;
}
@media screen and (max-width:1043px){
  .BasicKit{
    margin:50px auto 100px auto;
  }
  .BasicKit__quiryBtn{
    position: absolute;
    top:105%;
    left:50%;
    transform:translate(-50%,-50%);
  }
}
