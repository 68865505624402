.ShoppingCard{
  width:calc(50% - 50px);
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 200px 50px 25px auto auto;
  grid-template-areas:
  'img img img img'
  'title title title title'
  'subTitle subTitle subTitle subTitle'
  'modules modules others others'
  '. . button button';
  border-radius: 5px;
  margin-left:50px;
  margin-bottom:50px;
  color:#fff;
  grid-gap: 10px;
  padding:50px;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}
.ShoppingCard__img{
  grid-area:img;
  object-fit:contain;
  width:100%;
}
.ShoppingCard__img img{
  object-fit:contain;
  height:100%;
}
.ShoppingCard__title{
  grid-area:title;
  text-align: left;
  align-self: center;
}
.ShoppingCard__subTitle{
  grid-area:subTitle;
  text-align: left;
}
.ShoppingCard__modules{
  grid-area:modules;
  /* align-self: center; */
  text-align:left;
}
.ShoppingCard__others{
  grid-area:others;
  /* align-self: center; */
  text-align:left;
}
.ShoppingCard__add{
  grid-area:button;
  align-self: center;
  justify-self: center;
  transition:0.3s;
  width:60%;
  padding:20px 10px;
  opacity: 0.9;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
}
.ShoppingCard__add:hover{
  cursor:pointer;
  opacity:1;
}
.ShoppingCard__add:active{
  opacity:0.7;
}
.ShoppingCard__add:focus{
  outline:0;
}
@media screen and (max-width:1200px){
  .ShoppingCard{
    grid-template-areas:
    'img img img img'
    'title title title title'
    'subTitle subTitle subTitle subTitle'
    'modules modules others others'
    '. button button button';
  }
}
@media screen and (max-width:1043px){
  .ShoppingCard{
    width:calc(90%);
    margin:25px auto;
    padding:25px;
    grid-template-areas:
    'img img img img'
    'title title title title'
    'subTitle subTitle subTitle subTitle'
    'modules modules others others'
    'button button button button';
  }
  .ShoppingCard__add{
    justify-self: center;
    transition:0.3s;
    width:80%;
    padding:10px;
    opacity: 0.9;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  }
}

/* .ShoppingCard__add button{
  background-color:#222;
}
.ShoppingCard__add button:hover{
  background-color:#111;
}
.ShoppingCard__add button:active{
  background-color:#000;
} */
