.train .train_title{
    width: 80%;
    margin: 50px auto;
    text-align: left;
    font-weight: 600;
}
.flex{
    display: flex;
}
.train .train_content{
    width: 80%;
    margin: 50px auto;
    text-align: left;
}
.train .train_content .img1{
    width: 100%;
}
.train .train_content span{
    font-size: 20px;
    font-weight: 500;
}
.train .train_content p{
    margin-left: 15px;
    text-indent: 2rem;
}
.train .train_content .train_process{
    justify-content: center;
    margin-top: 5%;
}
.train .train_content .train_process div{
    width: 100%;
    color: #000;
    background-color: rgb(255,192,0);
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 20px;
    margin:0 3%;
}
.train .train_content .train_process div h4{
    font-weight: 600;
    font-size: 22px;
}
.train .train_content .train_process img{
    width: 4;
    /* height: 10%; */
}
.train .train_content .img_list{
    list-style: none;
}
.train .train_content .img_list li{
    width: 100%;
    justify-content: center;
    margin: 0 1%;
}
.train .train_content .img_list .train11_img{
    justify-content: space-between;
    margin-top: 15px;
}
.train .train_content .img_list .train11_img img{
    width: 31%;
}