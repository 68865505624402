.Success{
  width:100%;
  margin-top:250px;
  margin-bottom:250px;
}
.Success__Icon{
  width:200px;
  margin:0 auto;
  animation-name: scale;
  animation-duration: 2s;
}
.Success__Content{
  margin-top:20px;
  font-size:40px;
  animation-name: fadeIn;
  animation-duration: 2s;
}
@keyframes scale {
    0% {transform:scale(0,0)}
    30% {transform:scale(1.2,1.2)}
    50% {transform:scale(1,1)}
    70% {tranform: scale(1.1,1.1)}
    100% {transform:scale(1,1)}
}
@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity:1)}
}

@media screen and (max-width:768px){
  .Success{
    margin-top:400px;
  }
}
