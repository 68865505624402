.nav{
	position:absolute;
	top:0;
	left:0;
	z-index:99;
	width:100%;
	height:100px;
	color:#fff;
	display:flex;
	justify-content: space-between;
}
.navDark{
	box-shadow:2px 0px 15px 0 rgba(0,0,0,0.1);
	color:#000;
}
.nav .logo {
	width:20%;
	margin-left:10%;
	vertical-align: middle;
	z-index:100;
	line-height: 100px;
    font-size: 25px;
    font-weight: bold;
}
.navDark .Nav__anchor{
	background-color: #fff;
}
.logo img{
	height:100%;
}
.navWrapper{
	width:50%;
	margin-right:5%;
}
.navList{
	list-style-type:none;
	display:flex;
	margin:0;
	padding:0;
	width:100%;
	justify-content: center;
}
.navList li{
	height:auto;
	font-weight:bold;
	color:inherit;
	position: relative;
	padding-top:40px;
	width:80%;
	margin:auto;
	height: 100px;
}
.navList .navDropdown li{
	height:auto;
	font-weight:bold;
	color:inherit;
	position: relative;
	padding-top: 10px;
	/* padding-top:40px; */
	width:80%;
	margin:auto;
	height: fit-content;
}
.nav .navList li:hover{
	box-shadow:2px 0px 15px 0 rgba(0,0,0,0.1);
	cursor:pointer;
}
.navDark .navList li:hover{
	box-shadow:none;
}
.navList li span{
	cursor:pointer;
}
.navList li span:hover{
	color:#CED5D8;
}
.navList a{
	text-decoration: none;
	color: inherit;
	transition:0.3s;
	width:inherit;
}
.navList a:hover{
	color:#CED5D8;
	text-decoration: none;
}
.navDropdown{
	width:100%;
	margin:0;
	position:absolute;
	top:100%;
	left:0;
	padding:0;
	display:none;
	transition:0.3s;
}
.navOpen{
	display:block;
	animation:fadeIn 0.3s linear;
}
.navDropdown ul{
	padding:0;
	width:100%;
}
.navDropdown ul li{
	margin:5px 0;
	display:block;
	width:100%;
	padding:10px 0;
	position:inherit;
	box-shadow:2px 0px 15px 0 rgba(0,0,0,0.1);
}
.navDark .navDropdown ul li:hover{
	box-shadow:2px 0px 15px 0 rgba(0,0,0,0.1);
}

/* medium */
@media screen and (max-width: 1047px) {

.navList li{
	width:80%;
}

}
@media screen and (max-width: 1200px) {
	.navWrapper{
		overflow: hidden;
		width:100%;
		float:right;
	}
	.nav .logo{
		/* margin-left: 10px; */
		display: inline-block;
        min-width: 100px;
	}
}
/* small */
@media screen and (max-width: 639px) {
.nav{
	color:#000;
}
.nav--active{
	color:#000;
	position:fixed;
}
.nav .logo {
	width:40%;
}
.navWrapper{
	overflow: hidden;
	width:30%;
	float:right;
}
#nav{
	display:none;
	position:fixed;
	top:0;
	left:0;
	text-align:center;
	height:100vh;
	padding:0;
	padding-top:100px;
	z-index:99;
	background-color:#fff;
	width:100%;
	overflow:hidden;
}
#nav li{
	display:block;
	padding:10px 0;
	background-color:#2D3B3D;
	margin:1px 0;
	color:#fff;
	width:100%;
}
.nav .navList li:hover{
	box-shadow:none;
}
#hamburger{
	float:right;
	overflow: hidden;
	vertical-align: middle;
	margin:32.5px 20px;
	z-index:100;
	position:relative;
}
.navButton{
	width:30px;
	height:5px;
	margin:5px 0;
	background-color:#fff;
	display:block;
	border-radius: 2px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.navDark .navButton{
	width:30px;
	height:5px;
	margin:5px 0;
	background-color:#000;
	display:block;
	border-radius: 2px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.navButtonOpened:first-child{
	width:30px;
	height:5px;
	margin:5px 0;
	background-color:#000;
	display:block;
	border-radius: 2px;
	-webkit-transform: rotate(-45deg) translate(-4px,-4px);
	-ms-transform: rotate(-45deg) translate(-4px,-4px);
	transform: rotate(-45deg) translate(-4px,-4px);
	-webkit-transform-origin: top right;
	-moz-transform-origin: top right;
	-ms-transform-origin: top right;
	-o-transform-origin: top right;
	transform-origin: top right;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.navButtonOpened:nth-child(2){
	width:30px;
	height:5px;
	margin:5px 0;
	background-color:#000;
	display:block;
	border-radius: 2px;
	opacity:0;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.navButtonOpened:last-child{
	width:30px;
	height:5px;
	margin:5px 0;
	background-color:#000;
	display:block;
	border-radius: 2px;
	-webkit-transform: rotate(45deg) translate(-4px,4px);
	-ms-transform: rotate(45deg) translate(-4px,4px);
	transform: rotate(45deg) translate(-4px,4px);
	-webkit-transform-origin:bottom right;
	-moz-transform-origin:bottom right;
	-ms-transform-origin:bottom right;
	-o-transform-origin:bottom right;
	transform-origin:bottom right;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
.navDropdown{
	position:static;
}
.navDropdown ul{
	margin:0;
}
#nav .navDropdown ul li{
	border:0;
	box-shadow:none;
}

}
