.Login {
  width: 30%;
  /* height:400px; */
  background-color: #fff;
  text-align: left;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  margin: 150px auto;
  position: relative;
}

.Login__account {
  width: 70%;
  margin: 0 auto;
  margin-top: 25px;
  padding: 30px 10px;
}

.Login__button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 5px 30px;
  font-size: 16px;
  cursor: pointer;
}

.Login__button--press {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border: none;
  padding: 5px 30px;
  font-size: 16px;
  cursor: pointer;
}

.Login__button--wrapper {
  float: right;
  position: relative;
  margin-right: 15%;
  margin-top: 50px;
  width: auto;
}

.Login__pwd {
  width: 70%;
  margin: 10px auto;
  padding: 10px;
}

.Login__title {
  text-align: left;
  margin: 0;
  padding: 5px 0;
  display: block;
}

.Login__input {
  display: list-item;
  margin: 0 auto;
  padding: 10px 0;
  width: 100%;
  display: block;
  border: none;
  outline: none;
  border-bottom: 2px solid #000;
}

.Login__register {
  margin-left: 15%;
  margin-top: 50px;
  display: inline-block;
}

.Login__prompt {
  position: absolute;
  color: red;
}

.Login__forget {
  position: absolute;
  right: 15%;
  top: 230px;
}

.Login__resetEmail {
  width: 100%;
  margin-bottom: 50px;
  font-size: 24px;
  border: 0;
  border-bottom: 3px solid #000;
}

.Login__resetEmail:focus {
  outline: 0;
}

.Login__recaptcha {
  position: absolute;
  top: 330px;
  left: 50%;
  transform: translateX(-50%);
}

.Login__resetEmail--prompt {
  position: absolute;
  color: red;
  top: 60%;
}

.Login__googleSignIn {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding: 25px 0;
  width: 70%;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
}

.Login__googleSignIn div span {
  cursor: pointer;
}

.Login__googleSignIn img {
  width: 20px;
  margin-left: 5px;
}

#Login__modal--forget {
  padding-top: 300px;
}

@media screen and (max-width:768px) {
  .Login {
    width: 80%;
  }
}

@media screen and (max-width:350px) {
  .Login {
    width: 100%;
  }
}

.qqLogin {
  position: absolute;
  width: 50px;
  opacity: 0;
}
.qqLogin a{
  display: inline-block;
  width: 50px;
}