#result{
	margin-top:50px;
	margin-left:10%;
	width:80%;
	color:#B6B7B6;
	font-size:20px;
	display:grid;
	grid-template-rows: 60px 100px 100px 150px 100px;
	grid-template-columns: repeat(10,1fr);
	grid-template-areas:
	"header header header header header header header header header header "
	"video video video video video content content content content content "
	"video video video video video content content content content content"
	"video video video video video content content content content content"
	"theme moduleIcon moduleIcon moduleIcon moduleIcon content content content content content"
}

#result header{
	background-color: #3E454C;
	padding:20px;
	color:#fff;
	grid-area: header;
}
#video{
	background-color:#E8E8E8;
	grid-area: video;
	position: relative;
}
#video iframe{
	position:absolute;
	z-index:1;
	top:0;
	left:0;
}
#video span{
	z-index:0;
	overflow: hidden;
}
#video span img{
	object-fit:cover;
}
#result .theme{
	grid-area:theme;
	background-color:#E3E3E3;
	padding:10px;
}
#result .moduleIcon{
	grid-area:moduleIcon;
	background-color:#F0F0F0;
	display:grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 100%;
	grid-template-areas:
	"mIcon iIcon oIcon cIcon";
}
#result .icon{
	background-color:#D6D5D5;
	margin-left:10%;
	margin-top:10%;
	margin-bottom:10%;
	border-radius: 2px;
}
#result .mIcon{
	grid-area: mIcon;
}
#result .iIcon{
	grid-area: iIcon;
}
#result .oIcon{
	grid-area: oIcon;
}
#result .cIcon{
	grid-area: cIcon;
}
#content{
	grid-area: content;
	display:grid;
	grid-template-columns: 1;
	grid-template-rows: 10% 10% 1fr 1fr 1fr;
	grid-template-areas:
	"."
	"h2"
	"p1"
	"p2"
	".";
	position: relative;
	background-color: #F0F0F0;
	color:#231F20;
}
#content #title{
	width:50%;
	appearance:none;
	list-style-type: none;
	color:#000;
	border:0;
	border-radius: 2px;
	font-size:24px;
	margin:0;
	padding:5px 0;
	padding-right:20px;
	background-color:#fff;
	cursor:pointer;
}
#titles{
	width:50%;
	appearance:none;
	list-style-type: none;
	color:#000;
	border:0;
	border-radius: 2px;
	font-size:24px;
	margin:0;
	padding:5px 0;
	padding-right:20px;
	background-color:#fff;
	cursor:pointer;
}
#content .option::after{
	position:absolute;
	right:50%;
	top:0;
	content:"▾";
	color:#000;
	display:block;
}
#content #title option{
	appearance:none;
	font-size:14px;
}
#content h2{
	grid-area:h2;
	text-align:left;
	padding:0 50px;
	height:100%;
	align-self:end;
	margin:0;
	position:relative;
}
#content .p1{
	grid-area:p1;
	text-align:left;
	padding:0 50px;
}
#content .p2{
	grid-area:p2;
	text-align:left;
	padding:0 50px;
	color: #aaa;
}
#content #placeholder{
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	z-index:0;
	color:#B6B7B6;
}





@media screen and (max-width:1047px){

#result{
		display: grid;
		grid-template-columns: repeat(10,1fr);
		grid-template-rows: 60px auto auto 100px 100px;
		grid-template-areas:
		"header header header header header header header header header header "
		"video video video video video video video video video video "
		"video video video video video video video video video video "
		"theme theme moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon"
		"content content content content content content content content content content"
		"content content content content content content content content content content";
}

#content{
	grid-area: content;
	overflow-y: scroll;
	grid-template-columns: 1;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
	"h2"
	"p1"
	"p2";
	position: relative;
	background-color: #F0F0F0;
}
#result header{
	grid-area: header;
}
#video{
	grid-area: video;
}
#result .moduleIcon{
	grid-area:moduleIcon;
}
#result .theme{
	grid-area:theme;
}
#content #title{
	font-size:24px;
}
#content .option::after{
	right:50%;
	top:30%;
}
#content h2{
	padding-top:30px;
	align-self:baseline;
}
#content .p2{
	padding-bottom:50px;
}

}






@media screen and (max-width:639px){

#result{
	grid-template-rows: 60px 100px 100px 50px 50px;
}
header{
	font-size:14px;
}
#content{
	grid-area: content;
	display:grid;
	grid-template-columns: 1;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
	"h2"
	"p1"
	"p2";
	position: relative;
	background-color: #F0F0F0;
}
#content #title{
	width:60%;
	font-size:16px;
}
#content .option::after{
	right:40%;
	top:20%;
}
#content h2{
	padding:10px 10px;
	font-size:16px;
	align-self:baseline;
}
#content .p1{
	padding:0 10px;
	font-size:12px;
	margin:0;
}
#content .p2{
	padding:0 10px;
	font-size:12px;
	margin-bottom:50px;
}
#content span{
	font-size:14px;
}

}

/* IoT模块 */
#resultIoT{
	margin-top:50px;
	margin-left:10%;
	width:80%;
	color:#B6B7B6;
	font-size:20px;
	display:grid;
	grid-template-rows: 60px 100px 100px 150px 100px;
	grid-template-columns: repeat(10,1fr);
	grid-template-areas:
	"header header header header header header header header header header "
	"video video video video video content content content content content "
	"video video video video video content content content content content"
	"video video video video video content content content content content"
	"theme moduleIcon moduleIcon moduleIcon moduleIcon content content content content content"
}

#resultIoT header{
	background-color: #3E454C;
	padding:20px;
	color:#fff;
	grid-area: header;
}
#videoIoT{
	background-color:#E8E8E8;
	grid-area: video;
	position: relative;
}
#videoIoT iframe{
	position:absolute;
	z-index:1;
	top:0;
	left:0;
}
#videoIoT span{
	z-index:0;
	overflow: hidden;
}
#videoIoT span img{
	object-fit:cover;
}
#resultIoT .theme{
	grid-area:theme;
	background-color:#E3E3E3;
	padding:10px;
}
#resultIoT .moduleIcon{
	grid-area:moduleIcon;
	background-color:#F0F0F0;
	display:grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 100%;
	grid-template-areas:
	"mIcon iIcon oIcon cIcon";
}
#resultIoT .icon{
	background-color:#D6D5D5;
	margin-left:10%;
	margin-top:10%;
	margin-bottom:10%;
	border-radius: 2px;
}
#resultIoT .mIcon{
	grid-area: mIcon;
}
#result .iIcon{
	grid-area: iIcon;
}
#resultIoT .oIcon{
	grid-area: oIcon;
}
#resultIoT .cIcon{
	grid-area: cIcon;
}
#contentIoT{
	grid-area: content;
	display:grid;
	grid-template-columns: 1;
	grid-template-rows: 10% 10% 1fr 1fr 1fr;
	grid-template-areas:
	"."
	"h2"
	"p1"
	"p2"
	".";
	position: relative;
	background-color: #F0F0F0;
	color:#231F20;
}
#contentIoT #title{
	width:50%;
	appearance:none;
	list-style-type: none;
	color:#000;
	border:0;
	border-radius: 2px;
	font-size:24px;
	margin:0;
	padding:5px 0;
	padding-right:20px;
	background-color:#fff;
	cursor:pointer;
}
#titlesIoT{
	width:50%;
	appearance:none;
	list-style-type: none;
	color:#000;
	border:0;
	border-radius: 2px;
	font-size:24px;
	margin:0;
	padding:5px 0;
	padding-right:20px;
	background-color:#fff;
	cursor:pointer;
}
#contentIoT .option::after{
	position:absolute;
	right:50%;
	top:0;
	content:"▾";
	color:#000;
	display:block;
}
#contentIoT #titleIoT option{
	appearance:none;
	font-size:14px;
}
#contentIoT h2{
	grid-area:h2;
	text-align:left;
	padding:0 50px;
	height:100%;
	align-self:end;
	margin:0;
	position:relative;
}
#contentIoT .p1{
	grid-area:p1;
	text-align:left;
	padding:0 50px;
}
#contentIoT .p2{
	grid-area:p2;
	text-align:left;
	padding:0 50px;
}
#contentIoT #placeholderIoT{
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	z-index:0;
	color:#B6B7B6;
}





@media screen and (max-width:1047px){

#resultIoT{
		display: grid;
		grid-template-columns: repeat(10,1fr);
		grid-template-rows: 60px auto auto 100px 100px;
		grid-template-areas:
		"header header header header header header header header header header "
		"video video video video video video video video video video "
		"video video video video video video video video video video "
		"theme theme moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon moduleIcon"
		"content content content content content content content content content content"
		"content content content content content content content content content content";
}

#contentIoT{
	grid-area: content;
	overflow-y: scroll;
	grid-template-columns: 1;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
	"h2"
	"p1"
	"p2";
	position: relative;
	background-color: #F0F0F0;
}
#resultIoT header{
	grid-area: header;
}
#videoIoT{
	grid-area: video;
}
#resultIoT .moduleIcon{
	grid-area:moduleIcon;
}
#resultIoT .theme{
	grid-area:theme;
}
#contentIoT #title{
	font-size:24px;
}
#contentIoT .option::after{
	right:50%;
	top:30%;
}
#contentIoT h2{
	padding-top:30px;
	align-self:baseline;
}
#contentIoT .p2{
	padding-bottom:50px;
}

}






@media screen and (max-width:639px){

#resultIoT{
	grid-template-rows: 60px 100px 100px 50px 50px;
}
header{
	font-size:14px;
}
#contentIoT{
	grid-area: content;
	display:grid;
	grid-template-columns: 1;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
	"h2"
	"p1"
	"p2";
	position: relative;
	background-color: #F0F0F0;
}
#contentIoT #title{
	width:60%;
	font-size:16px;
}
#contentIoT .option::after{
	right:40%;
	top:20%;
}
#contentIoT h2{
	padding:10px 10px;
	font-size:16px;
	align-self:baseline;
}
#contentIoT .p1{
	padding:0 10px;
	font-size:12px;
	margin:0;
}
#contentIoT .p2{
	padding:0 10px;
	font-size:12px;
	margin-bottom:50px;
}
#contentIoT span{
	font-size:14px;
}

}

.active{
	opacity: 1 !important;
    background: #9cc8e7;
}
.active:hover{
	transform: none !important;
}
