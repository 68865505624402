.UGCHeaderList{
	width:150px;
}
.UGCHeaderList img{
	border-radius:100%;
	cursor:pointer;
}
.UGCHeaderList .UGCHeaderList--active{
	box-shadow:0px 0px 5px 5px #7CCBDC;
}

/* medium */
@media screen and (max-width:1047px){

.UGCHeaderList{
	width:100px;
}

}

/* small */
@media screen and (max-width:639px){

.UGCHeaderList {
	width:50px;
}

}
