footer {
    width: 100%;
    background-color: #333;
    margin: 0;
    margin-top: 100px;
    position: relative;
		overflow: hidden;
}
footer #contentInfo section{
	margin-left:10%;
}
footer #contentInfo ul{
	display:inline;
	color:#fff;
	width:25%;
	float:left;
	text-align:left;
	font-size:24px;
	padding:0;
	margin:50px 0;
}
footer #contentInfo section ul span{
	margin-bottom:15px;
	display:block;
}
footer #contentInfo section .footerLogo{
  width:20%;
}
footer #contentInfo ul li{
	color:#fff;
	list-style-type: none;
	text-align:left;
	font-size:14px;
	padding:10px 0;
}
footer #contentInfo section ul li a{
  color:#fff;
  text-decoration: none;
  transition:0.3s;
}
footer #contentInfo section ul li a:hover{
  opacity:0.5;
}
footer #contentInfo ul li:first-child{
	margin-top:30px;
}
footer .author {
    padding:25px 0;
    text-align: center;
    color:#fff;
    width:100%;
    display:inline-block;
}
footer #author p{
  width:50%;
  margin:0 auto;
  text-align: center;
}
footer #social {
    width: 20%;
    position: absolute;
    bottom: 15%;
    right: 10%;
    cursor: pointer;
}

footer #social span {
    display: inline-block;
    width: 20%;
    margin: 0 10%;
    padding: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
}

footer #social span:hover {
    -webkit-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
}

footer .news:hover img{
	position:absolute;
	right:17%;
	top:24%;
	margin-left:-115px;
	padding-top:30px;
	display:block;
}
footer .news img{
	height:120px;
	display:none;
}
@media screen and (max-width:480px){
footer #contentInfo ul{
	display:inline;
	color:#fff;
	width:calc(100%/3);
	float:left;
	text-align:left;
	font-size:24px;
	padding:0;
	margin:50px 0;
	font-size:16px;
}
footer #contentInfo ul span{
	height:30px;
	display:block;
	text-align:left;
}
footer #contentInfo section:first-child ul span {
	text-align: center;
}
footer .footerLogo{
	position: absolute;
	left:50%;
	top:50%;
	transform:translateX(-50%);
}
footer #contentInfo ul li{
	color:#fff;
	list-style-type: none;
	text-align:left;
	font-size:12px;
	padding:10px 0;
}
footer #contentInfo ul li:first-child{
	margin-top:0;
  padding:0;
}
footer #social {
    width: 50%;
    position: absolute;
    top: 60%;
    left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
    cursor: pointer;
}
footer #author {
    padding: 25px 10%;
		margin-top:300px;
}

}

