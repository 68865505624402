.curriculum .content{
	width:60%;
	margin:100px auto;
	text-align: left;
	font-size:16px;
	padding:5% 10%;
	color:#fff;
	background-color:#77A7B6;
	/* background-image:url('../img/curriculumBg.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%; */
}
.curriculum__section{
	margin:100px 0;
}
.curriculum__content{
	width:80%;
	margin:50px auto;
}
/* medium */
@media screen and (max-width:1047px){

.curriculum .content{
	background-size: cover;
	font-size:14px;
	padding:5% 10%;
}

}

/* small */
@media screen and (max-width:639px){

.curriculum .content{
	background-image:none;
	font-size:14px;
	padding:10%;
	color:#fff;
	text-align:justify;
}

}
