.Order{
  display:grid;
  grid-template-columns: 35% 35% 30%;
  grid-template-areas:
  'title title title'
  'shoppingCard shoppingCard list';
  grid-row-gap: 50px;
}
.Order__title{
  grid-area:title;
}
.Order__shoppingCard{
  grid-area:shoppingCard;
  display:flex;
  flex-wrap: wrap;
}
.Order__list{
  grid-area:list;
  padding:0 30px;
  position:fixed;
  width:25%;
  right:0;
  top:250px;
}
.Order__contact{
  grid-column: 1/4;
}
@media screen and (max-width:768px){
  .Order{
    grid-template-columns: 100%;
  }
  .Order__list{
    grid-area:list;
    padding:0;
    position:fixed;
    width:100%;
    right:0;
    top:inherit;
    bottom:0;
  }

}
