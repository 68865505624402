.cardSmallFn{
	width:calc(90%/4);
	border-radius:15px;
	margin:0 1.25%;
	margin-bottom:2.5%;
}
.cardSmallFn img{
	border-radius:15px 15px 0 0;
}
.cardSmallFnTitle{
  padding-bottom:10px;
	font-size:26px;
	font-weight: 400;
}
.cardSmallFn .like{
	position:absolute;
	width:50px;
	height:50px;
	right:5%;
	bottom:1%;
	background-color: transparent;
	border:0;
	cursor:pointer;
}
.cardSmallFn .like:focus{
	outline:none;
}
.cardSmallFn .like span{
	position: absolute;
	top:50%;
}
/* medium */
@media screen and (max-width:1047px){

.cardSmallFn{
	width:calc(90%/3);
}

}
/* small */
@media screen and (max-width:639px){

.cardSmallFn{
	width:calc(90%/1);
	margin-bottom:10%;

}
.cardSmallFnTitle{
	font-size:20px;
	font-weight: 400;
}

}
