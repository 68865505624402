.PCS{
	margin:100px 10%;
	display:flex;
	flex-wrap:wrap;
	justify-content: space-between;
}
.scroll{
	width:calc(90%/3);
	transition:0.5s;
	display:flex;
	text-align: justify;
	margin-bottom:50px;
	flex-wrap: wrap;
}

/* small */
@media screen and (max-width:639px){
.scroll{
	width:100%;
	transition:1s;
	display:flex;
	text-align: justify;
	margin-bottom:50px;
	flex-wrap: wrap;
}
}
