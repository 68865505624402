.slider .content{
	position:absolute;
	top:50%;
	left:50%;
	z-index:1;
	width:30%;
	transform: translate(-50%,-50%);
}
.slider .content h1{
	width:100%;
	text-align:center;
	color:#fff;
	line-height:150%;
}
.slider .content button{
	padding:10px;
	color:#000;
	background-color:#fff;
	width:50%;
	border-radius:25px;
	border:0;
	font-size:20px;
	font-weight:bold;
	cursor:pointer;
}
/* medium */
@media screen and (max-width:1047px){

.slider .content{
	position:absolute;
	bottom:30%;
	left:10%;
	z-index:1;
	width:50%;
}

}

/* small */
@media screen and (max-width:639px){
.slider .content{
	width:70%;
	left:50%;
	top:50%;
	transform:translate(-50%,-50%);
}
.slider .content h1{
	width:100%;
	font-size:26px;
	text-align:center;
}
.slider .content button{
	padding:10px;
	width:50%;
	font-size:16px;
	float:none;
}
}
