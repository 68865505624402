.button{
	background-color: #F3CE85;
	padding:10px 20px;
	border:0;
	border-radius:2px;
	color:#fff;
	box-shadow:2px 2px 5px 0 rgba(0,0,0,0.2);
	cursor:pointer;
}

.button--king{
	font-size:24px;
	padding:20px 40px;
}
.button--medium{
	font-size:18px;
	padding:10px 20px;
}
@media screen and (max-width:639px){
	.button--king{
		font-size:18px;
	}
}
