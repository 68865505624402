.contact{
	width:80%;
	margin-left: 10%;
	margin-top:50px;
}
.contact .wrapper{
	padding:0;
	margin:0;
	display:flex;
	flex-wrap:wrap;
}
.contact .wrapper div{
	text-align: left;
  width:50%;
	margin:50px 0;
}

@media screen and (max-width:640px){
.contact .wrapper div{
	text-align: left;
  width:100%;
	margin:50px 0;
}
}
