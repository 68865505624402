*{
    box-sizing: border-box;
}
ul{
    list-style: none;
}
.showContent{
    width: 80%;
    margin: 0 auto;
    text-align: left;
    font-family: SimSun;
}
.showContent h2{
    text-align: left;
    font-size: 48px;
    margin-top: 90px;
    font-weight: 700;
}
.showContent .demoSwitch{
    cursor: pointer;
    padding: 8px 10px 10px 10px;
    border: 1px solid #205CC6;
    float: right;
}
.showContent .demoSwitch:nth-of-type(1){
    margin-top: -50px;
    background: #205CC6;
}
.showContent .demoSwitch:nth-of-type(2){
    margin-top: -50px;
    margin-right: 49px;
}
.showContent .demoSwitch:nth-of-type(3){
    margin-top: -50px;
    background: #205CC6;
}
.showContent .demoSwitch:nth-of-type(4){
    margin-top: -50px;
    margin-right: 49px;
}
.showContent .themeTitle{
    display: flex;
    font-size: 1.75rem;
    padding: 0;
}
.showContent .themeTitle li{
    padding: 8px 0;
    margin: 0 2%;
    cursor: pointer;
}
.showContent .themeTitle li:nth-of-type(1){
    margin-left: 0;
}
.showContent .themeTitle li img{
    width: 100%;
}
.showContent .activeDemo{
    border-bottom: 5px solid #1F5CC6;
    color: #1F5CC6;
    font-weight: bold;
}

.showContent .themeImg{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    text-align: center;
    padding: 0;
    flex-wrap: wrap;
}
.showContent .themeImg li{
    position: relative;
    width: 24%;
    margin-right: 1%;
    margin-top: 2%;
    padding: 15px 0;
    font-size: 1.3rem;
    font-weight: 600;
    background-color: #fff;
    box-shadow: 0px 10px 14px 0px rgba(31, 92, 198, 0.06), 0px -4px 14px 0px rgba(31, 92, 198, 0.06);
    border-radius: 5px;
    
}

.showContent .twoSwichImg{
    text-align: center;
}
.showContent .twoSwichImg span{
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #E6E6E6;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}

.demoFooter{
    width: 100%;
    position: relative;
}
.demoFooter p{
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    font-size: 1.8rem;
}
.demoFooter p span{
    font-size: 3rem;
    font-weight: bold;
}

.showContent .demoHide{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #205CC6;
    padding: 1.3rem;
    color: #fff;
    text-align: left;
    font-size: 1rem;
    border-radius: 5px;
    opacity: 0;
    cursor: pointer;
}

.showContent .demoHide:hover{
    opacity: 0.94;
    transition: all .5s;
}

.showContent .contentTitle{
    font-weight: 600;
}


/* demo 详情页 */
.demoDetail .demodetailContent{
    width: 80%;
    margin: 100px auto;
    text-align: left;
}

.demodetailContent .detailAbstract{
    display: flex;
    justify-content: space-between;
}

.demodetailContent .detailAbstract .abstractLeft{
    /* width: 35%; */
}
.demodetailContent .detailAbstract .abstractLeft img{
    float: left;
}
.demodetailContent .detailAbstract .abstractLeft h1{
    /* display: inline-block; */
    margin: 0;
    padding: 0;
    font-size: 3.2rem;
    margin-left: 25px;
}
.demodetailContent .detailAbstract .abstractLeft span{
    font-size: 1.35rem;
    margin-top: 14px;
    margin-left: 25px;
    color: #666666;
}
.demodetailContent .detailAbstract .abstractLeft .detailTitle{
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 100px;
    margin-bottom: 60px;
    color: #000;
}
.demodetailContent .detailAbstract .abstractLeft .contentTitle{
    font-weight: 600;
    text-align: left;
    margin-left: 0;
    font-size: inherit;
}
.demodetailContent .detailAbstract .abstractLeft .detailTheme{
    margin-top: 15%;
    padding: 40px 0;
    text-align: center;
    border-top: 1px solid #1F5CC6;
    border-bottom: 1px solid #1F5CC6;
    font-size: 1.4rem;
    font-weight: bold;
    color: #1F5CC6;
}
.demodetailContent .detailAbstract .abstractRight{
    width: 60%;
    background: #FFFFFF;
    border: 1px solid #C7D9F9;
    box-shadow: 0px 10px 14px 0px rgba(31, 92, 198, 0.06), 0px -4px 14px 0px rgba(31, 92, 198, 0.06);
    position: relative;
}
.demodetailContent .detailAbstract .abstractRight img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.moduleImg{
    display: flex;
    padding-left: 0;
    font-family: SimSun;
}
.moduleImg li{
    width: 23%;
    margin: 0 1%;
    text-align: center;
}
.moduleImg li div{
    /* display: inline-block; */
    width: 100%;
    height: 90%;
    background: #FFFFFF;
    border: 1px solid #C7D9F9;
    box-shadow: 0px 10px 14px 0px rgba(31, 92, 198, 0.06), 0px -4px 14px 0px rgba(31, 92, 198, 0.06);
    margin-bottom: 3%;
}
.moduleImg li div img{
    float: none !important;
    display: inline-block;
    width: 80%;
    margin: 0 auto;
}
.moduleImg li:nth-of-type(4n+1){
    margin-left: 0;
}
