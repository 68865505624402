.curriculumKit__content{
  background-color:#FF7F66;
  color:#fff;
  width:80%;
  margin:0 auto;
  display:grid;
  grid-template-columns: repeat(4 , 1fr);
  grid-template-rows: 150px 150px auto auto;
  grid-template-areas:
  "img img title cat"
  "img img desc desc"
  "title__outline title__outline title__outline title__outline"
  "outline outline outline outline"
  "title__package title__package title__package title__package"
  "package package package package"
  "title__software title__software title__software title__software"
  "software software software software"
  "button button button button";
  grid-gap:50px;
  padding:50px;
  box-sizing: border-box;
  background-image: url('../img/curriculumKit__bg.png');
  background-size: contain;
  background-position: center;
}
.curriculumKit__img{
  grid-area:img;
  overflow:hidden;
  background-color: #fff;
  border-radius: 5px;
  align-self:center;
}
.curriculumKit__img img{
  height:300px;
  object-fit:contain;
  border-radius: 5px;
}
.curriculumKit__title{
  grid-area:title;
  justify-self:start;
  align-self:flex-end;
}
.curriculumKit__cat{
  grid-area:cat;
  justify-self:end;
  align-self: flex-end;
}
.curriculumKit__desc{
  grid-area:desc;
  text-align:left;
}
.curriculumKit__outline{
  grid-area:outline;
}

.curriculumKit__table{
  width:100%;
}
.curriculumKit__td{
  padding:20px 0;
  color:#2D3B3D;
}
.curriculumKit__td:nth-child(2n+1){
  background-color:rgba(255,255,255,0.8);
  width:70%;
}
.curriculumKit__td:nth-child(2n){
  background-color:#fff;
  width:30%;
}
.curriculumKit__package{
  grid-area:package;
}
.curriculumKit__software{
  grid-area:software;
  display:grid;
  grid-template-columns:repeat(4,1fr);
  grid-template-areas:
  "blockly blockly cad cad";
  grid-gap:50px;
}
.curriculumKit__button{
  grid-area:button;
  margin:100px;
}
.content__title{
  text-align:left;
  color:#fff;
}
.title__outline{
  grid-area:title__outline;
}
.title__package{
  grid-area:title__package;
}
.title__software{
  grid-area:title__software;
}
.software__blockly{
  grid-area:blockly;
}
.software__cad{
  grid-area:cad;
}
.content__bgColor--0{
  background-color:#FF7F66;
}
.content__bgColor--1{
  background-color:#56A6DC;
}
.content__bgColor--2{
  background-color:#502868;
}
.content__bgColor--3{
  background-color:#57AF6D;
}
@media screen and (max-width:639px){
  .curriculumKit__content{
    width:80%;
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: 150px 150px auto auto;
    grid-template-areas:
    "img img img img"
    "img img img img"
    "title title cat cat"
    "desc desc desc desc"
    "title__outline title__outline title__outline title__outline"
    "outline outline outline outline"
    "title__package title__package title__package title__package"
    "package package package package"
    "title__software title__software title__software title__software"
    "software software software software"
    "button button button button";
    grid-gap:10px;
    padding:10px;
    box-sizing: border-box;
    background-image: url('../img/curriculumKit__bg.png');
    background-size: contain;
    background-position: center;
  }
  .curriculumKit__button{
    grid-area:button;
    margin:100px 0;
  }
  .curriculumKit__software{
    grid-area:software;
    display:grid;
    grid-template-columns:repeat(4,1fr);
    grid-template-areas:
    "blockly blockly blockly blockly"
    "cad cad cad cad";
    grid-gap:50px;
  }
}
