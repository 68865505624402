.faq{
	width:100%;
	display:flex;
	margin-top:50px;
}
.faq .category{
	padding:50px 0;
	width:25%;
	background-color:#77A7B6;
}
.faq .category span{
	display:inline-block;
	width:100%;
	padding:25px 0;
}
.faq .faqList{
	width:75%;
	text-align: left;
	box-sizing: border-box;
	padding:50px 10%;
}
.faq .faqList .question{
	cursor:pointer;
}
.faq .faqList .question:before{
	content:'+';
	float:right;
}
.faq .faqList .question_active{
	color:#77A7B6;
	cursor:pointer;
}
.faq .faqList .question_active:after{
	content:'-';
	float:right;
}
.faq .faqList .answer{
	background-color:#EFEFEF;
	display:none;
}
.faq .faqList .answer_show{
	display:block;
	animation:fadeIn 0.3s linear;
	
}
