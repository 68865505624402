.Profile__body{
  margin-top:50px;
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap:50px;
}
.Profile__avatar{
  grid-column-start: 1;
  grid-column-end:3;
  width:200px;
  padding:25px;
  border-radius: 200px;
  background-color: #d6e4ea;
  overflow: hidden;
  margin:0 auto;
  border:10px solid #b8bfc1;
}
.Profile__blank{
  margin:50px auto;
  text-align: left;
  width:40%;
}
.Profile__label{
  width:100%;
  display:block;
}
.Profile__input{
  border:0;
  border-bottom:2px solid #000;
  width:100%;
  font-size:21px;
}
.Profile__select{
  border:0;
  border-radius:0;
  border-bottom:2px solid #000;
  width:100%;
  font-size:21px;
}
.Profile__input:focus{
  outline:0;
}
.Profile__editPassword{
  width:50%;
}
.input__wrapper{
  margin:20px 0;
}
