.Printer__body{
  width:100%;
  text-align: left;
}
.Printer__img{
  width:30%;
  margin:10%;
}
.Printer__title{
  width:20%;
  display:inline-block;
  text-align: left;
  vertical-align: middle;
}
.Printer__content{
  /* width:20%; */
  display:inline-block;
  text-align: left;
  vertical-align: middle;
}
.Printer-list__item{
  list-style-type: none;
  margin:12px 0;
}
@media screen and (max-width: 768px ){
  .Printer__img{
    width:80%;
    margin:10%;
  }
  .Printer__title{
    margin-left:10%;
    width:30%;
    font-size:12px;
    display:inline-block;
    text-align: left;
    vertical-align: top;
  }
  .Printer__content{
    width:auto;
    display:inline-block;
    text-align: left;
    vertical-align: top;
    font-size:12px
  }
}
