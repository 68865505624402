/*large*/
.title{
	width:250px;
	padding:15px 15px;
	text-align:center;
	margin:0 10%;
	margin-top:150px;
	font-size:21px;
	color:#fff;
	background-color:#5A7493;    /*#77A7B6*/
	box-shadow:0px 2px 15px 0 rgba(0,0,0,0.1);
	border-radius:2px;
}


/* medium */
@media screen and (max-width:1047px){


.title{
	width:200px;
	padding:15px 10px;
}


}

/* small */
@media screen and (max-width:689px){


.title{
	width:120px;
	padding:15px 10px;
}


}
