.TabBar{
  width:80%;
  margin: 100px auto;
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
}
.TabBar__link{
  display:block;
  width:20%;
}
.TabBar__tab{
  width:100%;
  padding:20px 10px;
  border-radius: 2px;
  border:none;
  background-color:#7ECEFD;
  color:#fff;
  font-size: 16px;
  cursor:pointer;
}
.TabBar__tab--active{
  background-color:#FF7F66;
}
.TabBar__tilt{
  border-radius:10px;
  overflow:hidden;
}
.TabBar__tilt--0{
  background-color: #FF7F66;
}
.TabBar__tilt--1{
  background-color: #7ECEFD;
}
.TabBar__tilt--2{
  background-color: #502868;
}
.TabBar__tilt--3{
  background-color: #57AF6D;
}

.TabBar__image{
  padding:20%;
  padding-bottom:0;
}
@media screen and (max-width:639px){
  .TabBar__image{
    padding:0;
  }
  .TabBar__link{
    display:block;
    width:calc(50% - 20px);
    margin:10px;
  }
}
