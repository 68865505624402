.article{
	width:70%;
	margin:50px auto;
	margin-left:10%;
	text-align: left;
}
.article header{
	margin:50px auto;
}
.article header .tag{
	background-color:#2D3B3D;
	color:#fff;
	width:10%;
	padding:5px 5px;
	text-align:center;
	font-size:14px;
	border-radius:20px;
}
.article > p{
	line-height: 48px;
}
/* medium */
@media screen and (max-width:1047px){

.article header .tag{
	width:15%;
}

}

/* small */
@media screen and (max-width:639px){
.article{
	width:65%;
}
.article > p{
	line-height: 32px;
}
.article header .tag{
	width:30%;
}

}
