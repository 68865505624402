.Feedback__form{
  margin:200px;
  position: relative;
  z-index: 1;
}
.Feedback__intro{
  width:50%;
  text-align:left;
  margin:50px auto;
}
.Feedback__iframe{
  margin-top:100px;
}
.Form__input{
  border:2px solid #000;
}
@media screen and (max-width:639px){
  .Feedback__form{
    margin:0;
    margin-top:100px;
  }
  .Feedback__intro{
    width:80%;
    text-align:left;
    margin:50px auto;
  }
}
