.QueryItem{
  width:100%;
  margin:10px auto;
  display:grid;
  grid-template-columns: repeat(5,1fr);
  grid-template-areas:
  'orderNum orderNum orderNum status status'
  'title title quantity subTotal .'
  'college college . quotation quotation';
  box-shadow:2px 2px 10px rgba(0 ,0 ,0,0.1);
  border-radius: 10px;
}
.QueryItem__num{
  grid-area:orderNum;
  margin: 10px 20px;
  align-self: center;
  text-align: left;
}
.QueryItem__status{
  grid-area:status;
  margin:10px;
}
.QueryItem__college{
  grid-area:college;
  margin:10px;
  align-self:center;
}
.QueryItem__quotation{
  grid-area:quotation;
  margin:10px;
}
.QueryItem__list{
  grid-area:list;
  display:flex;
}
.QueryItem__title{
  grid-area:title;
}
.QueryItem__quantity{
  grid-area:quantity;
}
.QueryItem__subTotal{
  grid-area:subTotal;
}
