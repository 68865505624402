.demo{
	width:80%;
	margin:50px auto;
	display:flex;
}
.demo .result{
	height:auto;
	width:calc(100%/4);
	background-color: #F0F0F0;
	border-right:1px solid #DBDBDB;
}
.demo .result header{
	background-color:#3E454C;
	color:#fff;
	padding:20px;
}
.demo .result p{
	padding:0 5%;
	text-align: left;
}
.demo .modules{
	width:75%;
	display:flex;
	flex-wrap: wrap;
}
.demo .modules .module:nth-child(2n) header{
	color:#fff;
	background-color:#FF7F66;
	padding:20px;
}
.demo .modules .module:nth-child(2n+1) header{
	color:#fff;
	background-color:#7ECEFD;
	padding:20px;
}
.demo .modules .module{
	width:calc(100%/3);
	height:auto;
	background-color: #F0F0F0;
}
.demo .modules ul{
	display:flex;
	list-style-type:none;
	padding:0;
	margin:0;
	flex-wrap:wrap;
}
.demo .modules ul li{
	width:calc(90%/4);
	padding:1.25%;
	cursor:pointer;
}
.demo .modules ul li img{
	border-radius:5px;
}
.demo .modules ul li span{
	font-size:12px;
}
/*medium*/
@media screen and (max-width:1047px){

.demo .modules ul li{
	width:calc(90%/3);
	padding:calc(10%/6);
	cursor:pointer;
}
.demo .modules .module{
	height:auto;
}
}

/*small*/
@media screen and (max-width:689px){

.demo {
	flex-wrap:wrap;
}
.demo .result {
	width:100%;
	height:auto;
}
.demo .modules{
	width:100%;
}
.demo .modules .module{
	height:auto;
	width:calc(100%/2);
}
.demo .modules .module span{
	display:none;
}
.demo .modules ul li{
	width:calc(90%/2);
	padding:2.5%;
	cursor:pointer;
}


}
