.OrderList{
  box-shadow:1px 1px 5px rgba(0, 0, 0, 0.2);
  padding:20px 20px;
  overflow-y:auto;
  height:300px;
}
.OrderList__amount{
  padding-top:5px;
  text-align: right;
}
.OrderList__btn{
  margin:20px 0;
}
.OrderList__toggle{
  display:none;
}
@media screen and (max-width:768px) {
  .OrderList{
    box-shadow:1px 1px 5px rgba(0, 0, 0, 0.2);
    padding:20px 20px;
    overflow-y:auto;
    height:300px;
    display:none;
    background-color:#fff;
    animation-name: slide;
    animation-duration: 0.3s;
  }
  .OrderList--expand{
    display:block;
  }
  @keyframes slide{
    from { height:0}
    to{height:300px}
  }
  .OrderList__btn{
    margin:0;
    text-align: right;
  }
  .OrderList__amount{
    position: absolute;
    padding:0;
    bottom:10px;
    left:2%;
    text-align: left;
  }
  .OrderList__bar{
    background-color:#fff;
  }
  .OrderList__title{
    display:none;
  }
  .OrderList__toggle{
    box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.1);
    display:block;
    position: absolute;
    top:-12px;
    left:50%;
    transform:translate(-50%,-50%);
    width:50px;
    background-color:#fff;
    border-radius: 10px 10px 0 0;
    margin: 0 auto;
  }
  .OrderList__toggle:before{
    content:'▲';
  }
  .OrderList__toggle--open:before{
    content:'▼';
  }

}
