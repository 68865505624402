.Activation{
  margin:50px auto;
  height:300px;
}
.Activation__Desc{
  margin:50px 0;
}
.Activation__active{
  margin:50px 0;
}
.Activation__resend{
  margin:10px 0;
}
