/*large*/
.titleSub{
	width:10%;
	padding:10px 0;
	text-align:center;
	margin:0 10%;
	margin-top:150px;
	font-size:16px;
	color:#fff;
	background-color:#2D3B3D;
	box-shadow:0px 2px 15px 0 rgba(0,0,0,0.1);
	border-radius:2px;
}





/* medium */
@media screen and (max-width:1047px){


.titleSub{
	width:150px;
	padding:15px 5%;
}


}





/* small */
@media screen and (max-width:639px){


.titleSub{
	width:100px;
	padding:15px 5%;
}


}
