.sidebar{
	width:20%;
	position:fixed;
	top:30%;
	right:0;
}
.sidebar ul h4{
	background-color: #2D3B3D;
	width:50%;
	color:#fff;
	margin:20px auto;
	padding:10px 0;
	border-radius: 2px;
}
.sidebar ul li{
	width:50%;
	margin:0 auto;
	padding:20px 0;
	cursor:pointer;
}
.sidebarList{
	list-style-type:none;
}
.sidebarListActive{
	list-style-type:disc;
}

/* medium */
@media screen and (max-width:1047px){

.sidebar ul h4{
	padding:10px 20px;
	width:60%;
	font-size:16px;
	margin:0 auto;
}
.sidebar ul li{
	width:80%;
	margin:0 auto;
	padding:20px 0;
	cursor:pointer;
}

}

/* small */
@media screen and (max-width:639px){
.sidebar{
	top:23%;
	right:0;
}
.sidebar ul{
	padding:0;
}
.sidebar ul h4{
	padding:10%;
	width:60%;
	font-size:16px;
	margin:0 auto;
}
.sidebar ul li{
	width:60%;
	margin:0 auto;
	padding:20px 0;
	cursor:pointer;
}

}
